<template>
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!load"
      :overflow="true"
      :load-bg="currentLoadingBg"
    ></Loading>
  </transition>

  <!-- Main content -->
  <div
    class="container-fluid
    mt-nav"
  >
    <div
      data-aos="fade-up"
      data-aos-delay="1600"
      data-aos-duration="500"
    >
      <div
        class="
          row
        "
      >
        <h1 class="col-12 col-lg-7">
          {{ city[0].City }}
        </h1>
        <h2
          class="
            h4
            col-12 col-lg-7
            text-uppercase
          "
        >
          {{ city[0].SchoolName }}
        </h2>
        <p
          class="
            col-12 col-lg-7
            en line-break
            mt-3 mt-lg-4
          "
        >
          {{ city[0].SchoolDescriptionEng }}
        </p>
        <p
          class="
            col-12 col-lg-7
            hk line-break
            mt-3
          "
          >
          {{ city[0].SchoolDescriptionChin }}
        </p>
        <a :href="city[0].Website" target="_blank"
          class="
            text-uppercase text-decoration-none
            d-flex align-items-center
          "
        >
          website
          <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
        </a>
        <a
          v-if="this.slug === 'rome'"
          href="https://vimeo.com/606691538"
          target="_blank"
          class="
            text-uppercase text-decoration-none
            d-flex align-items-center
            mt-3
          "
        >
          E-xhibition Trailer
          <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
        </a>
        <a
          v-if="this.slug === 'rome'"
          href="https://vimeo.com/606691902"
          target="_blank"
          class="
            text-uppercase text-decoration-none
            d-flex align-items-center
            mt-3
          "
        >
          Director's speech
          <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
        </a>
      </div>
    </div>
    <div
      id="buttonsFadeUp"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-anchor="#buttonsFadeUp"
      data-aos-anchor-placement="bottom-bottom"
    >
      <div
        class="w-100 mt-5"
        :class="{
          'mb-7' : !city[0].Phyxhibition && !city[0].VirtualExhibition,
        }"
      >
        <button
          class="btn btn-primary"
          @click="goToExhibition"
        >
          <span class="h3 en regular text-uppercase">
            e-xhibition
          </span>
        </button>
      </div>
      <div
        class="w-100 mt-3"
        v-if="city[0].VirtualExhibition"
      >
        <button
          class="btn btn-primary"
          @click="goToVirutalExhibition"
        >
          <span class="h3 en regular text-uppercase">
            Virtual Exhibition
          </span>
        </button>
      </div>
      <div class="w-100 mt-3"
        v-if="city[0].YoungCurrent"
      >
        <button
          class="btn btn-primary"
          @click="goToYoungCurrent"
        >
          <span class="h3 en regular text-uppercase">
            Youth Current <span class="d-none d-lg-inline">Exhibition</span>
          </span>
        </button>
      </div>
      <div
        class="w-100 mt-3"
        :class="{
          'mb-7' : city[0].current_programmes.length === 0,
        }"
        v-if="city[0].Phyxhibition"
      >
        <button
          class="btn btn-primary"
          @click="goToPhyxhibition"
        >
          <span class="h3 en regular text-uppercase">
            phy-xhibition
          </span>
        </button>
      </div>
    </div>
    <!-- Current Programme -->
    <div id="currentProgFadeUp">
      <h2
        v-if="city[0].current_programmes.length > 0"
        class="mt-5 mb-3"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-anchor="#currentProgFadeUp"
        data-aos-anchor-placement="bottom-bottom"
      >
        Current Programme
      </h2>
    </div>
    <!-- Panel Discussion -->
    <template v-for="(programme, index) in city[0].current_programmes" :key="programme.Title">
      <div
        v-if="city[0].current_programmes.length > 0 && programme.Type === 'PanelDiscussion'"
        :id="`panel${index}`"
      >
        <div
          class="col-12 col-lg-7 mb-4"
          data-aos="fade-up"
          :data-aos-anchor="`#panel${index}`"
          data-aos-duration="500"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h3 class="text-uppercase title-font">
            panel discussion
          </h3>
          <h3 class="mt-3">
            {{ programme.Title }}
          </h3>
          <div class="en">
            {{ programme.Venue }}
          </div>
          <div class="en">
            {{ programme.Period }}
          </div>
          <div class="en">
            {{ programme.Time }}
          </div>
          <div>
            <span class="">Panelists : </span>
            <span class="en">
              <span class="me-2">
                {{ programme.Speaker1 }}
              </span>
              <span class="me-2" v-if="programme.Speaker2">
                ,&nbsp;{{ programme.Speaker2 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker3">
                {{ programme.Speaker3 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker4">
                {{ programme.Speaker4 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker5">
                {{ programme.Speaker5 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker6">
                {{ programme.Speaker6 }}
              </span>
            </span>
          </div>
          <div>
            <span class="" v-if="programme.Moderator1">
              Moderators :&nbsp;
            </span>
            <span class="en">
              <span class="me-2" v-if="programme.Moderator1">
                {{ programme.Moderator1 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Moderator2">
                {{ programme.Moderator2 }},&nbsp;
              </span>
            </span>
          </div>
          <div class="mt-3">
            {{ programme.Description }}
          </div>
          <a
            :href="programme.Link"
            class="
            btn
            btn-primary
            mt-3
            "
          >
            <div class="d-flex align-items-center body-regular text-uppercase">
              <span class="h4 mb-0">enter</span>
              <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-4" />
            </div>
          </a>
        </div>
      </div>
    </template>
    <!-- Panel Discussion -->
    <!-- Global Screening -->
    <template v-for="(programme, index) in city[0].current_programmes" :key="programme.Title">
      <div
        v-if="city[0].current_programmes.length > 0 && programme.Type === 'GlobalScreening'"
        :id="`globalScreening${index}`"
      >
        <div
          class="col-12 col-lg-7 mb-4"
          data-aos="fade-up"
          :data-aos-anchor="`#globalScreening${index}`"
          data-aos-duration="500"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h3 class="text-uppercase title-font">
            global screening
          </h3>
          <h3 class="mt-3">
            {{ programme.Title }}
          </h3>
          <div class="en">
            {{ programme.Venue }}
          </div>
          <div class="en">
            {{ programme.Period }}
          </div>
          <div class="en">
            {{ programme.Time }}
          </div>
          <div>
            <span class="">Speakers : </span>
            <span class="en">
              <span class="me-2">
                {{ programme.Speaker1 }}
              </span>
              <span class="me-2" v-if="programme.Speaker2">
                ,&nbsp;{{ programme.Speaker2 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker3">
                {{ programme.Speaker3 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker4">
                {{ programme.Speaker4 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker5">
                {{ programme.Speaker5 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker6">
                {{ programme.Speaker6 }}
              </span>
            </span>
          </div>
          <div>
            <span class="" v-if="programme.Moderator1">
              Moderators :&nbsp;
            </span>
            <span class="en">
              <span class="me-2" v-if="programme.Moderator1">
                {{ programme.Moderator1 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Moderator2">
                {{ programme.Moderator2 }},&nbsp;
              </span>
            </span>
          </div>
          <a
            :href="programme.Link"
            class="btn btn-primary mt-2"
          >
            <div class="d-flex align-items-center body-regular text-uppercase">
              <span class="h4 mb-0">enter</span>
              <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-4" />
            </div>
          </a>
        </div>
      </div>
    </template>
    <!-- Global Screening -->
    <!-- Online Residency -->
    <template v-for="(programme, index) in city[0].current_programmes" :key="programme.Title">
      <div
        v-if="city[0].current_programmes.length > 0 && programme.Type === 'OnlineResidency'"
        :id="`online${index}`"
      >
        <div
          class="col-12 col-lg-7 mt-3 mb-7"
          data-aos="fade-up"
          :data-aos-anchor="`#online${index}`"
          data-aos-duration="500"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h3 class="text-uppercase title-font">
            Online Residency
          </h3>
          <h3 class="mt-3">
            {{ programme.Title }}
          </h3>
          <div class="en">
            {{ programme.Venue }}
          </div>
          <div class="en">
            {{ programme.Period }}
          </div>
          <div class="en">
            {{ programme.Time }}
          </div>
          <div>
            <span class="">Curator : </span>
            <span class="en">
              <span class="me-2">
                {{ programme.Speaker1 }}
              </span>
              <span class="me-2" v-if="programme.Speaker2">
                ,&nbsp;{{ programme.Speaker2 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker3">
                {{ programme.Speaker3 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker4">
                {{ programme.Speaker4 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker5">
                {{ programme.Speaker5 }},&nbsp;
              </span>
              <span class="me-2" v-if="programme.Speaker6">
                {{ programme.Speaker6 }}
              </span>
            </span>
          </div>
          <a
            :href="programme.Link"
            class="btn btn-primary mt-2"
          >
            <div class="d-flex align-items-center body-regular text-uppercase">
              <span class="h4 mb-0">enter</span>
              <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-4" />
            </div>
          </a>
        </div>
      </div>
    </template>
    <!-- Online Residency -->
    <!-- Current Programme -->
  </div>
</template>

<script>
import gql from 'graphql-tag';
import gsap from 'gsap';
import AOS from 'aos';
import emitter from '@/components/tools/emitter';
import Loading from '@/components/front/LoadingCity.vue';
import 'aos/dist/aos.css';

export default {
  name: 'City',
  data() {
    return {
      slug: '',
      timeCityLoadStatus: false,
      apolloLoad: false,
      currentLoadingBg: 'bg-citya-day',
      loadBg: {
        hk: {
          day: '#004ADD',
          night: '#000E2A',
        },
        mo: {
          day: '#07EFEF',
          night: '#0D1C1C',
        },
        bj: {
          day: '#6600CC',
          night: '#1C0037',
        },
        sf: {
          day: '#48EF20',
          night: '#072300',
        },
        tll: {
          day: '#F93822',
          night: '#230400',
        },
        rm: {
          day: '#FFCD00',
          night: '#39331C',
        },
        citya: {
          day: '#F7F5F5',
          night: '#242020',
        },
      },
      city: [
        {
          City: '',
          SchoolName: '',
          SchoolDescriptionEng: '',
          SchoolDescriptionChin: '',
          Website: '',
          Phyxhibition: false,
          YoungCurrent: false,
          VirtualExhibition: false,
          current_programmes: {
            Title: '',
            Slug: '',
            Type: '',
            Venue: '',
            Period: '',
            Time: '',
            Speaker1: '',
            Speaker2: '',
            Speaker3: '',
            Speaker4: '',
            Speaker5: '',
            Speaker6: '',
            Moderator1: '',
            Moderator2: '',
            city: {
              City: '',
            },
            Link: '',
            Description: '',
          },
        },
      ],
      currentCity: '',
      currentHour: null,
    };
  },
  components: {
    Loading,
  },
  computed: {
    load() {
      console.log(`time status: ${this.timeCityLoadStatus}`);
      console.log(`apollo status: ${this.apolloLoad}`);
      AOS.init({ once: false, disable: 'phone' });
      AOS.refresh();
      return this.timeCityLoadStatus && this.apolloLoad;
    },
  },
  watch: {
    slug() {
      emitter.emit('city-route-change');
    },
    city: {
      deep: true,
      handler() {
        emitter.emit('emit-city', this.city[0].City);
        this.slug = this.$route.params.slug;
      },
    },
  },
  apollo: {
    city: {
      query: gql`
        query City($Slug: String!) {
          cities(where: {Slug: $Slug}) {
            City
            SchoolName
            SchoolDescriptionEng
            SchoolDescriptionChin
            Website
            Slug
            artists { 
              Name
              Slug
            }
            Phyxhibition
            YoungCurrent
            VirtualExhibition
            current_programmes {
              Title
              Slug
              Type
              Venue
              Period
              Time
              Speaker1
              Speaker2
              Speaker3
              Speaker4
              Speaker5
              Speaker6
              Moderator1
              Moderator2
              city {
                City
              }
              Link
              Description
            }
          }
        }
      `,
      variables() {
        return {
          Slug: this.$route.params.slug,
        };
      },
      update(data) {
        // console.log(this.$route.params.slug, data.cities);
        return data.cities;
      },
      error(error) {
        console.error("We've got an error!", error);
      },
      loadingKey: 'loadingQueriesCount',
      // watchLoading will be called whenever the loading state changes
      // eslint-disable-next-line no-unused-vars
      watchLoading(isLoading, countModifier) {
        // isLoading is a boolean
        // countModifier is either 1 or -1

        this.apolloLoad = false;
        if (this.currentLoadingBg !== 'bg-citya-day' && isLoading) {
          this.updateLoadBg();
        }
        // console.log('watch loading', isLoading, countModifier);
        if (!isLoading) {
          this.apolloLoad = true;
          console.log('load complete');
        }
      },
    },
  },
  methods: {
    /**
     * Page Load effects
     */
    updateLoadBg() {
      switch (this.$route.params.slug) {
        case 'hong-kong':
          this.currentLoadingBg = 'bg-hongkong-day';
          break;
        case 'macao':
          this.currentLoadingBg = 'bg-macau-day';
          break;
        case 'beijing':
          this.currentLoadingBg = 'bg-beijing-day';
          break;
        case 'san-francisco':
          this.currentLoadingBg = 'bg-sf-day';
          break;
        case 'tallinn':
          this.currentLoadingBg = 'bg-tallinn-day';
          break;
        case 'rome':
          this.currentLoadingBg = 'bg-rome-day';
          break;
        default:
          this.currentLoadingBg = 'bg-citya-day';
          break;
      }
    },
    removeLoadBg(lastCitySlug) {
      switch (lastCitySlug) {
        case 'hong-kong':
          return 'bg-hongkong-day';
        case 'macao':
          return 'bg-macau-day';
        case 'beijing':
          return 'bg-beijing-day';
        case 'san-francisco':
          return 'bg-sf-day';
        case 'tallinn':
          return 'bg-tallinn-day';
        case 'rome':
          return 'bg-rome-day';
        default:
          return 'bg-citya-day';
      }
    },
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 0.8,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 800);
    },
    /**
     * Main methods
     */
    goToExhibition() {
      this.$router.push(`/city/${this.slug}/artists`);
    },
    goToVirutalExhibition() {
      this.$router.push(`/city/${this.slug}/virtual-exhibition`);
    },
    goToYoungCurrent() {
      this.$router.push(`/city/${this.slug}/youth-current-artists`);
    },
    goToPhyxhibition() {
      this.$router.push(`/city/${this.slug}/phy-xhibition`);
    },
  },
  mounted() {
    console.clear();
    // AOS.init();
    this.slug = this.$route.params.slug;
    this.updateLoadBg();
    emitter.on('time-city-load', (data) => {
      console.log('timeCityLoadStatus', data);
      this.timeCityLoadStatus = data;
    });
  },
  unmounted() {
    console.log('unmounted');
  },
};
</script>
